@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
html, body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
label.required::after {
  color: red;
  font-family: FontAwesome;
  content: " ";
  font-size: x-small;
  vertical-align: super;
}
.user-error-box {
  display: inherit;
  padding: 1%;
  color: white;
  background-color: red !important;
  font-size: 12px;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}